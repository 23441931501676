import React from 'react';

import { token } from '@atlaskit/tokens';
import UploadIcon from '@atlaskit/icon/core/upload';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';

type GlyphProps = {
	primaryColor?: IconColor;
	label?: string;
	testId?: string;
};
export const Import16Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon', '#44546F');
	const label = props.label || '';
	return <UploadIcon label={label} color={primaryColor} />;
};
